import React from 'react'
import PropTypes from 'prop-types'

const Header = (props) => (
    <header id="header" style={props.timeout ? {display: 'none'} : {}}>
        <div className="logo">
            <span className="icon fa-diamond"></span>
        </div>
        <div className="content">
            <div className="inner">
                <h1>Petch Umpriwan</h1>
                <p>Hi, Nice to meet you everyone ! <br></br> You can find more info about me from links below.</p>
            </div>
        </div>
        <nav>
            <ul>
                {/* <li><a href="javascript:;" onClick={() => {props.onOpenArticle('intro')}}>Intro</a></li> */}
                <li><a href="https://medium.com/@petchumpriwan">Blog</a></li>
                <li><a href="https://www.linkedin.com/in/petchumpriwan">About</a></li>
                <li><a href="mailto:petch.umpriwan@gmail.com">Contact</a></li>
            </ul>
        </nav>
        <div className='inner'>
            <br></br>
            <ul className="icons">
                <li><a href="https://www.facebook.com/petch.umpriwan" className="icon fa-facebook"><span className="label">Facebook</span></a></li>
                <li><a href="https://www.instagram.com/petch.umpriwan" className="icon fa-instagram"><span className="label">Instagram</span></a></li>
                <li><a href="https://www.twitter.com/petchumpriwan" className="icon fa-twitter"><span className="label">Twitter</span></a></li>
                <li><a href="https://github.com/petchumpriwan" className="icon fa-github"><span className="label">Github</span></a></li>
                <li><a href="https://www.linkedin.com/in/petchumpriwan" className="icon fa-linkedin"><span className="label">LinkedIn</span></a></li>
            </ul>
        </div>
    </header>
)

Header.propTypes = {
    onOpenArticle: PropTypes.func,
    timeout: PropTypes.bool
}

export default Header
